import React, { useState } from 'react';

function StackExchange({ color }: React.SVGProps<SVGSVGElement>) {
  const [isHover, setIsHover] = useState(false);
  const onMouseEnter = () => setIsHover(true);
  const onMouseLeave = () => setIsHover(false);

  return (
    <svg
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        fill={isHover ? 'lightgray' : color || '#fff'}
        d="M22 19.386v.69c0 1.169-.932 2.116-2.07 2.116h-.605L16.599 25v-2.808H11.07c-1.138 0-2.07-.948-2.07-2.12v-.687h13zm-13-3.41h12.914v2.652H9v-2.652zm0-3.429h12.914V15.2H9v-2.653zM19.893 9c1.126 0 2.02.948 2.02 2.12v.688H9v-.688C9 9.948 9.935 9 11.072 9h8.822z"
      />
      <circle cx="16" cy="16" r="15.5" stroke={isHover ? 'lightgray' : color || '#fff'} />
    </svg>
  );
}

const MemoStackExchange = React.memo(StackExchange);
export default MemoStackExchange;
