import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import {
  AppBar,
  Drawer,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  Theme,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import MemoTezosIcon from '../../../icons/Tezos';
// eslint-disable-next-line import/no-cycle
import { ListSubMenu } from '../ListMenu/ListSubMenu';

interface HeaderDesignProps {
  theme: Theme;
}

const CustomGridItem = styled(Grid)<HeaderDesignProps>`
  padding-left: ${({ theme }) => theme.spacing(5)};
  ${({ theme }) => `${theme.breakpoints.up('md')} {
    padding-left: ${theme.spacing(7.5)};
  }`}
`;

const CustomToolbar = styled(Toolbar)<HeaderDesignProps>`
  padding: ${({ theme }) => theme.spacing(2)};
  min-height: fit-content !important;
  width: 100%;
  box-sizing: border-box;
  ${({ theme }) => `${theme.breakpoints.up('md')} {
    padding: ${theme.spacing(2.5)} ${theme.spacing(5)};
  }`}
`;

const DrawerHeader = styled.div<HeaderDesignProps>`
  padding: ${({ theme }) => theme.spacing(2)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(5)};
`;

const CustomListItem = styled(ListItem)<HeaderDesignProps>`
  padding: 0 ${({ theme }) => theme.spacing(3)} ${({ theme }) => theme.spacing(4)};
  &:hover {
    background-color: transparent;
  }
`;

const CustomIconButton = styled(IconButton)<HeaderDesignProps>`
  width: 44px;
  color: ${({ theme }) => theme.palette.grey[100]};
  &:hover {
    background-color: transparent;
  }
`;

const StyledLink = styled(Link)<HeaderDesignProps>`
  color: ${({ theme }) => theme.palette.grey[100]};
  &.active,
  &:hover {
    color: ${({ theme }) => theme.palette.grey[100]};
  }

  ${({ theme }) => `${theme.breakpoints.down('md')} {
        font-size: 2.5rem;
    }`}
`;

const StyledList = styled(List)`
  flex: 1;
  overflow-y: auto;
`;

export interface HeaderMenuItem {
  label: string;
  url?: string;
  subMenu?: HeaderMenuItem[];
}

export interface HeaderProps {
  title?: string;
  menuItems: HeaderMenuItem[];
  mobileMenuClickHandler?: () => void;
  hasStaticBackgroundColor?: boolean;
}

export const Header: React.FC<HeaderProps> = ({
  menuItems,
  mobileMenuClickHandler,
  hasStaticBackgroundColor = false,
}) => {
  const theme = useTheme();
  const [openMenu, setOpenMenu] = React.useState(false);
  const [bgColor, setBgColor] = React.useState(theme.palette.grey[200]);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const handleOpen = React.useCallback(() => {
    setOpenMenu(true);
    if (mobileMenuClickHandler) mobileMenuClickHandler();
  }, [mobileMenuClickHandler]);

  const handleClose = React.useCallback(() => {
    setOpenMenu(false);
    if (mobileMenuClickHandler) mobileMenuClickHandler();
  }, [mobileMenuClickHandler]);

  const links = React.useMemo(() => {
    const OuterContainer = isMobile ? CustomListItem : CustomGridItem;
    return menuItems.map((item) => (
      <OuterContainer item key={`${item.label}-${item.url}`} theme={theme}>
        {item.subMenu && item.subMenu.length > 0 ? (
          <ListSubMenu label={item.label} menuItems={item.subMenu} />
        ) : (
          <StyledLink href={item.url} theme={theme} underline="none" variant="h6">
            {item.label}
          </StyledLink>
        )}
      </OuterContainer>
    ));
  }, [menuItems, theme, isMobile]);

  const DesktopMenu = React.useCallback(() => {
    return (
      <Grid container item alignItems="center" justifyContent="flex-end" md={10}>
        {links}
      </Grid>
    );
  }, [links]);

  const MobileMenu = React.useCallback(() => {
    return (
      <>
        <Grid container item justifyContent="flex-end" xs={6}>
          <CustomIconButton onClick={handleOpen} aria-label="menu" theme={theme}>
            <MenuIcon />
          </CustomIconButton>
        </Grid>
        <Drawer
          anchor="right"
          open={openMenu}
          sx={{
            '& .MuiDrawer-paper': {
              backgroundColor: theme.palette.primary.main,
              width: '100%',
            },
          }}
          SlideProps={{
            easing: 'in',
          }}
        >
          <DrawerHeader theme={theme}>
            <Link href="https://tezos.com/">
              <MemoTezosIcon width={200} />
            </Link>
            <CustomIconButton onClick={handleClose} aria-label="closeMenu" theme={theme}>
              <CloseIcon />
            </CustomIconButton>
          </DrawerHeader>
          <StyledList>{links}</StyledList>
        </Drawer>
      </>
    );
  }, [theme, openMenu, links, handleClose, handleOpen]);

  useEffect(() => {
    hasStaticBackgroundColor || openMenu
      ? setBgColor(theme.palette.grey[100])
      : setBgColor(theme.palette.grey[200]);
  }, [hasStaticBackgroundColor, openMenu, theme]);

  return (
    <AppBar
      position="absolute"
      sx={{
        backgroundColor: 'transparent',
        top: 0,
        backdropFilter: `${bgColor === theme.palette.grey[200] ? 'blur(5px)' : undefined}`,
      }}
      elevation={0}
    >
      <CustomToolbar theme={theme}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item aria-hidden="true" marginRight="auto" xs={6} md={2}>
            <Link href="https://tezos.com/">
              <MemoTezosIcon width={200} />
            </Link>
          </Grid>
          {isMobile ? <MobileMenu /> : <DesktopMenu />}
        </Grid>
      </CustomToolbar>
    </AppBar>
  );
};
