import React, { useState } from 'react';

function Twitter({ color, ...rest }: React.SVGProps<SVGSVGElement>) {
  const [isHover, setIsHover] = useState(false);
  const onMouseEnter = () => setIsHover(true);
  const onMouseLeave = () => setIsHover(false);

  return (
    <svg
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
      {...rest}
    >
      <path
        fill={isHover ? 'lightgray' : color || '#fff'}
        d="M25.247 10.715a7.305 7.305 0 01-2.096.574 3.658 3.658 0 001.604-2.02c-.716.426-1.5.726-2.317.886a3.65 3.65 0 00-6.217 3.329 10.362 10.362 0 01-7.522-3.813 3.65 3.65 0 001.13 4.872 3.64 3.64 0 01-1.653-.457v.047a3.65 3.65 0 002.927 3.579 3.675 3.675 0 01-1.648.062 3.65 3.65 0 003.41 2.533A7.322 7.322 0 017.46 21.82a10.32 10.32 0 005.593 1.64c6.713 0 10.382-5.56 10.382-10.382 0-.157-.004-.315-.01-.472a7.417 7.417 0 001.819-1.888l.002-.002z"
      />
      <circle cx="16" cy="16" r="15.5" stroke={isHover ? 'lightgray' : color || '#fff'} />
    </svg>
  );
}

const MemoTwitter = React.memo(Twitter);
export default MemoTwitter;
