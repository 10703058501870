import React, { useState } from 'react';
import styled from '@emotion/styled';
import sanitizeHtml from 'sanitize-html';
import { Grid, Container, useTheme, Link, Divider, Theme, useMediaQuery } from '@mui/material';
import { Typography } from '../../atoms/Typography';
import { Button } from '../../atoms/Button';

const FooterStyled = styled.footer<{ theme: Theme }>`
  margin-top: auto;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.grey[900]};
  color: ${({ theme }) => theme.palette.grey[100]};

  a,
  a:hover {
    color: ${({ theme }) => theme.palette.grey[100]};
    text-decoration: none;
  }

  a.white {
    color: ${({ theme }) => theme.palette.grey[100]};
    font-family: GT-Eesti-Regular, Noto Sans SC, sans-serif;
    font-weight: 400;
  }

  hr.MuiDivider-root {
    border-bottom-color: ${({ theme }) => theme.palette.grey[100]};

    ${({ theme }) => theme.breakpoints.up('lg')} {
      margin-bottom: 1.7rem;
      margin-top: 8rem;
    }
    ${({ theme }) => theme.breakpoints.down('lg')} {
      margin-bottom: 4rem;
      margin-top: 4rem;
    }
  }
`;

const FooterContainer = styled(Container)<{ theme: Theme }>`
  padding-bottom: 8.75rem;
  padding-top: 4.5rem;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  ${({ theme }) => theme.breakpoints.between('sm', 'md')} {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }
  ${({ theme }) => theme.breakpoints.between('md', 'lg')} {
    padding-left: 8.65rem;
    padding-right: 8.65rem;
  }
`;

const StyledLink = styled(Link)`
  cursor: pointer;
  font-weight: 100;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;

interface FooterLink {
  label: string;
  href?: string;
  handleLinkClick?: () => void | Promise<void>;
}
export interface ShareItem {
  icon: React.ReactNode;
  url?: string;
  showInMobile?: boolean;
}

interface FooterLinkGroup {
  /**
   * Title for the group
   */
  title: string;
  /**
   * Links to show in the group
   */
  links: FooterLink[];
}
export interface FooterProps {
  /**
   * Title for the group
   */
  logo: string | React.ReactNode;
  /**
   * Get in touch description
   */
  getInTouch?: string;
  /**
   * All the links to show in the footer
   */
  linkGroups: FooterLinkGroup[];
  /**
   * Sharing icons
   */
  sharingList?: ShareItem[];
  /**
   * Privacy policy section
   */
  privacyPolicy?: {
    icon?: string | React.ReactNode;
    title: string;
    url: string;
    target?: '_blank' | '_self';
  };
}

export const Footer: React.FC<FooterProps> = ({
  logo,
  getInTouch,
  sharingList,
  linkGroups,
  privacyPolicy,
}) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const [sharing, setSharing] = useState<ShareItem[]>(sharingList || []);

  React.useEffect(() => {
    if (isTablet && sharingList) {
      setSharing(sharingList?.filter((icon) => icon.showInMobile === true));
    } else {
      setSharing(sharingList || []);
    }
  }, [isTablet, sharingList]);

  return (
    <FooterStyled theme={theme}>
      <FooterContainer maxWidth="xl" theme={theme}>
        <Grid container direction="row" flexWrap="wrap">
          <Grid item container direction="column" xs={12} lg={6} rowSpacing={4}>
            <Grid item>{logo}</Grid>
            {sharing.length > 0 && (
              <Grid item container>
                {sharing.map((item, index) => (
                  <Link
                    key={`sharing-${index}`}
                    target="_blank"
                    href={item.url}
                    mr={2}
                    py={0.5}
                    px={0.75}
                  >
                    {item.icon}
                  </Link>
                ))}
              </Grid>
            )}
            {getInTouch && (
              <Grid item>
                <Typography
                  style={{
                    fontFamily: 'Roboto,Noto Sans SC,sans-serif',
                    fontSize: '0.75rem',
                    lineHeight: 1,
                  }}
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(getInTouch, {
                      allowedTags: ['a'],
                      allowedAttributes: {
                        a: ['href', 'class', 'target', 'rel'],
                      },
                    }),
                  }}
                />
              </Grid>
            )}
          </Grid>
          <Grid item container direction="row" xs={12} lg={6} pt={{ xs: 8, lg: 0 }}>
            {linkGroups.map((group, index) => (
              <Grid
                container
                item
                xs={6}
                md={4}
                lg={6}
                direction="column"
                key={`${group.title}-${index}`}
              >
                <Typography
                  color={theme.palette.grey[100]}
                  mb={4}
                  component="h6"
                  size="h6"
                  style={{ fontWeight: 700, fontSize: '1.125rem' }}
                >
                  {group.title}
                </Typography>

                {group.links.map((link, idx) =>
                  link.href ? (
                    <StyledLink
                      key={`${link.label}-${idx}`}
                      href={link.href}
                      target="_blank"
                      mb={1.5}
                    >
                      {link.label}
                    </StyledLink>
                  ) : (
                    <Button
                      key={`${link.label}-${idx}`}
                      variant="text"
                      fontcolor={theme.palette.grey[100]}
                      onClick={link.handleLinkClick}
                      label={link.label}
                      hasIcon={false}
                    />
                  ),
                )}
              </Grid>
            ))}
          </Grid>
          {privacyPolicy && (
            <Grid item container direction="column" xs={12}>
              <Divider />
              <Grid item>{privacyPolicy.icon}</Grid>
              <Grid item>
                <Link href={privacyPolicy.url} target={privacyPolicy.target}>
                  <Typography
                    style={{
                      fontFamily: 'GT-Eesti-Regular,Noto Sans SC,sans-serif',
                      fontSize: '0.75rem',
                      fontWeight: 400,
                      lineHeight: 1,
                    }}
                  >
                    {privacyPolicy.title}
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          )}
        </Grid>
      </FooterContainer>
    </FooterStyled>
  );
};
