import React, { useState } from 'react';

function Discord({ color }: React.SVGProps<SVGSVGElement>) {
  const [isHover, setIsHover] = useState(false);
  const onMouseEnter = () => setIsHover(true);
  const onMouseLeave = () => setIsHover(false);
  return (
    <svg
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        fill={isHover ? 'lightgray' : color || '#fff'}
        d="M14.592 13.97c-.542 0-.969.476-.969 1.056 0 .578.437 1.055.969 1.055.541 0 .968-.477.968-1.055.011-.581-.427-1.055-.968-1.055zm3.468 0c-.542 0-.969.476-.969 1.056 0 .578.437 1.055.969 1.055.541 0 .968-.477.968-1.055-.001-.581-.427-1.055-.968-1.055z"
      />
      <path
        fill={isHover ? 'lightgray' : color || '#fff'}
        d="M22.678 6H9.947A1.952 1.952 0 008 7.957v12.844c0 1.083.874 1.957 1.947 1.957H20.72l-.505-1.759 1.217 1.131 1.149 1.064L24.625 25V7.957A1.951 1.951 0 0022.678 6zM19.01 18.407s-.342-.408-.626-.771c1.244-.352 1.719-1.13 1.719-1.13-.39.256-.76.438-1.093.562a6.679 6.679 0 01-3.838.398 7.944 7.944 0 01-1.396-.41 5.399 5.399 0 01-.693-.321c-.029-.021-.057-.029-.085-.048a.116.116 0 01-.039-.03c-.171-.094-.266-.16-.266-.16s.456.76 1.663 1.121c-.285.36-.637.789-.637.789-2.099-.067-2.896-1.444-2.896-1.444 0-3.059 1.368-5.538 1.368-5.538 1.368-1.027 2.669-.998 2.669-.998l.095.114c-1.71.495-2.499 1.245-2.499 1.245s.21-.114.561-.275c1.016-.446 1.823-.57 2.156-.599.057-.009.105-.019.162-.019a7.756 7.756 0 014.778.893s-.751-.712-2.366-1.206l.133-.152s1.302-.029 2.669.998c0 0 1.368 2.479 1.368 5.538 0-.001-.807 1.376-2.907 1.443z"
      />
      <circle cx="16" cy="16" r="15.5" stroke={isHover ? 'lightgray' : color || '#fff'} />
    </svg>
  );
}

const MemoDiscord = React.memo(Discord);
export default MemoDiscord;
