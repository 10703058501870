import React, { useState } from 'react';

function Gitlabs({ color }: React.SVGProps<SVGSVGElement>) {
  const [isHover, setIsHover] = useState(false);
  const onMouseEnter = () => setIsHover(true);
  const onMouseLeave = () => setIsHover(false);

  return (
    <svg
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        fill={isHover ? 'lightgray' : color || '#fff'}
        d="M16 24l-2.946-9.226h5.892L16 24zm0 0l-7.075-9.226h4.13L16 24zm-7.075-9.226L16 24l-7.75-5.729a.631.631 0 01-.221-.694l.896-2.803zm0 0L10.7 9.217a.31.31 0 01.11-.156.3.3 0 01.47.156l1.774 5.557H8.925zM16 24l2.946-9.226h4.128L16 24zm7.074-9.226l.896 2.803a.631.631 0 01-.222.694L16 24l7.075-9.226zm0 0h-4.128l1.774-5.557a.31.31 0 01.11-.157.3.3 0 01.47.157l1.774 5.557z"
      />
      <circle cx="16" cy="16" r="15.5" stroke={isHover ? 'lightgray' : color || '#fff'} />
    </svg>
  );
}

const MemoGitlabs = React.memo(Gitlabs);
export default MemoGitlabs;
