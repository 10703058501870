import React from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  Button as MaterialButton,
  ButtonProps as MaterialButtonProps,
  Theme,
  useTheme,
} from '@mui/material';
import styled from '@emotion/styled';

interface StyledButtonProps {
  bordercolor?: string;
  fontcolor?: string;
  theme: Theme;
  /**
   * Disable Hover effects
   */
  disableHover?: boolean;
  target?: '_blank' | '_self';
}

const StyledButton = styled(MaterialButton)<StyledButtonProps>`
  padding: 1rem 1.5rem;
  box-shadow: none;
  border-radius: 0;
  font-weight: normal;
  line-height: 1.5;
  text-transform: initial;
  font-family: 'GT-Eesti-Regular';

  &:not(.MuiButton-fullWidth) {
    width: fit-content;
  }

  &.MuiButton-sizeMedium {
    font-size: 1rem;
  }

  &.MuiButton-sizeLarge {
    font-size: 2rem;
    font-weight: bold;
  }

  &.MuiButton-outlined {
    border-width: 2px;
    border-radius: 2.75rem;
    background: linear-gradient(
      to left,
      transparent 50%,
      ${({ theme }) => theme.palette.primary.main} 50%
    );
    background-size: 200% 100%;
    background-position: right;

    &:hover {
      background-position: left;
      transition: all 250ms ease;
      box-shadow: none;
    }

    &.MuiButton-outlinedPrimary {
      border-color: ${({ theme }) => theme.palette.primary.main};
      color: ${({ theme }) => theme.palette.primary.main};

      &:hover {
        color: ${({ theme }) => theme.palette.grey[100]};
      }
    }

    &.MuiButton-outlinedSecondary {
      color: ${({ theme }) => theme.palette.grey[100]};
      border-color: ${({ theme }) => theme.palette.grey[100]};

      &:hover {
        border-color: ${({ theme }) => theme.palette.primary.main};
      }
    }
  }

  &.MuiButton-contained {
    border-radius: 2.75rem;

    &.MuiButton-containedPrimary {
      &:hover {
        background-color: ${({ theme }) => theme.palette.primary.dark};
        box-shadow: none;
      }
    }

    &.MuiButton-containedSecondary {
      background-color: ${({ theme }) => theme.palette.background.paper};
      color: ${({ theme }) => theme.palette.primary.main};
      &:hover {
        color: ${({ theme }) => theme.palette.primary.dark};
        background-color: ${({ theme }) => theme.palette.background.paper};
        box-shadow: none;
      }
    }
  }

  &.MuiButton-text {
    padding: 0;
    ${({ fontcolor }) => fontcolor && `color: ${fontcolor}`}

    &.MuiButton-textSizeMedium, &.MuiButton-textSizeSmall {
      font-size: 1rem;
    }

    &.MuiButton-textSizeLarge {
      font-size: 1.625rem;
      font-weight: normal;
    }
    &:hover {
      background-color: transparent;
    }

    .MuiButton-endIcon.MuiButton-iconSizeSmall {
      margin-left: 3px;
    }
  }
`;

export interface ButtonProps extends MaterialButtonProps {
  /**
   * Button contents
   */
  label: string;
  /**
   * Disable Hover effects
   */
  disableHover?: boolean;
  /**
   * If has icon or not
   */
  hasIcon?: boolean;
  /**
   * Button contains icon in LeftSide
   */
  icon?: string | React.ReactNode;
  /**
   * Button contains icon in RightSide
   */
  iconPosition?: 'left' | 'right';
  /**
   * for custom text color
   */
  fontcolor?: string;
}

export const Button: React.FC<ButtonProps> = ({
  variant,
  label,
  hasIcon = true,
  icon = <ArrowForwardIcon />,
  iconPosition = 'right',
  ...props
}) => {
  const theme = useTheme();
  return (
    <>
      <StyledButton
        {...props}
        theme={theme}
        variant={variant}
        startIcon={hasIcon && iconPosition === 'left' ? icon : null}
        endIcon={hasIcon && iconPosition === 'right' ? icon : null}
      >
        {label}
      </StyledButton>
    </>
  );
};
