import React, { useState } from 'react';

function Redit({ color }: React.SVGProps<SVGSVGElement>) {
  const [isHover, setIsHover] = useState(false);
  const onMouseEnter = () => setIsHover(true);
  const onMouseLeave = () => setIsHover(false);

  return (
    <svg
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        fill={isHover ? 'lightgray' : color || '#fff'}
        fillRule="evenodd"
        d="M26 16c0 5.523-4.477 10-10 10S6 21.523 6 16 10.477 6 16 6s10 4.477 10 10zm-3.593-.785c.161.23.253.503.263.785a1.46 1.46 0 01-.81 1.33c.012.147.012.293 0 .44 0 2.24-2.61 4.06-5.83 4.06s-5.83-1.82-5.83-4.06a2.86 2.86 0 010-.44 1.46 1.46 0 111.61-2.39 7.14 7.14 0 013.9-1.23l.74-3.47a.31.31 0 01.37-.24l2.45.49a1 1 0 11-.13.61L17 10.65l-.65 3.12A7.12 7.12 0 0120.2 15a1.46 1.46 0 012.207.215zm-9.569 1.23a1.001 1.001 0 101.665 1.111 1.001 1.001 0 00-1.665-1.112zm3.172 4.075c.887.037 1.76-.195 2.47-.73a.28.28 0 00-.39-.4A3.28 3.28 0 0116 20a3.27 3.27 0 01-2.08-.63.27.27 0 00-.38.38 3.84 3.84 0 002.47.77zm1.734-2.648c.165.11.349.208.546.208a.999.999 0 001.01-1.04 1 1 0 10-1.556.832z"
        clipRule="evenodd"
      />
      <circle cx="16" cy="16" r="15.5" stroke={isHover ? 'lightgray' : color || '#fff'} />
    </svg>
  );
}

const MemoRedit = React.memo(Redit);
export default MemoRedit;
