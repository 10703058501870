import { useLocation } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html';
import styled from '@emotion/styled';
import { Container, Grid, Paper, PaperProps, Theme, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { Button, ButtonProps } from '../../atoms/Button';
import { Typography } from '../../atoms/Typography';

const DEFAULT_ACTION_GROUP_SIZE = 3;

interface HeroWrapperProps {
  /**
   * Background image to show
   */
  backgroundImage?: string;
  /**
   * Background color to show
   */
  backgroundColor?: string;
  /**
   * Background gradient to show
   */
  backgroundGradient?: string;
  /**
   * Background Position
   */
  backgroundPosition?: string;
  /**
   * Background image Size
   */
  backgroundImageSize?: string;
  /**
   * Font color for dark background
   */
  fontColor?: string;
  /**
   * To show dotted images
   */
  hasExtraBackgroundImage?: boolean;
  /**
   * If there are 2 dotted images
   */
  isDoubleExtraBackgroundImage?: boolean;
  /**
   * Position of dotted images
   */
  extraBackgroundImagePosition?: 'left bottom' | 'right bottom';
  /**
   * Theme for sizing
   */
  theme?: Theme;
  /**
   * Text position
   */
  textPosition?: 'center' | 'left';

  fullWidth?: boolean;

  fullHeight?: boolean;
}

export interface HeroProps extends HeroWrapperProps, PaperProps {
  /**
   * Title text
   */
  title: string;
  /**
   * Title customized class
   */
  customTitleClass?: string;
  /**
   * Subtitle text
   */
  subtitle?: string | string[];
  description?: string | string[];
  titleFontSize?: string;
  subTitleFontSize?: string;
  descriptionFontSize?: string;
  alignText?: 'center' | 'left';
  /**
   * Number of buttons to show in a row (Default 3)
   */
  actionGroupSize?: number;
  /**
   * List of buttons with actions
   */
  actions?: ButtonProps[];

  getHeroHeight?: (args: number) => void;
}

const HeroWrapper = styled(Paper)<HeroWrapperProps>`
  background-image: ${({ backgroundImage, backgroundGradient }) =>
    `url(${backgroundImage}) , ${backgroundGradient}`};
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ fontColor }) => fontColor};
  background-position: ${({ backgroundPosition }) => backgroundPosition || 'center'};
  background-repeat: no-repeat;
  background-size: ${({ backgroundImageSize, backgroundGradient }) =>
    `${backgroundImageSize} ${backgroundGradient ? ', contain' : null}`};
  box-shadow: none;
  border-radius: 0;
  position: relative;
  padding: 175px 0 125px;
  ${({ fullHeight }) => fullHeight && 'min-height: 100vh'};
  box-sizing: border-box;
  display: flex;
  align-items: center;

  ${({ theme, backgroundGradient }) => `${theme.breakpoints.down('md')} {
    background-image: ${backgroundGradient};
    background-size: contain;

    &:before,
    &:after {
      background-size: contain;
    }
  }`}

  ${({ theme }) => `${theme.breakpoints.down('sm')} {
    padding: 146px 0 96px;

    &.homePage .MuiContainer-root {
      padding-left: ${theme.spacing(2.5)};
      padding-right: ${theme.spacing(2.5)};
    }
  }`}

  ${({ theme, fullWidth }) => `${theme.breakpoints.up('lg')} {
      .MuiContainer-root > .MuiGrid-container {
        ${
          !fullWidth &&
          `
          max-width: ${theme.spacing(107.5)};
        `
        }

        &.textCentered {
          margin: auto;
        }
      }
  }`}


  ${({ hasExtraBackgroundImage, isDoubleExtraBackgroundImage, extraBackgroundImagePosition }) =>
    hasExtraBackgroundImage &&
    `
    &:before,
    &:after {
      content: '';
      @extend %extra-image;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-image: url(/assets/images/dotted-wave-white.svg);
      background-position: left bottom;
      background-repeat: no-repeat;
      background-size: 567px;
      display: block;
    }
    &:before {
      transform: rotate(180deg);
      display: ${isDoubleExtraBackgroundImage ? 'block' : 'none'};
    }

    ${
      extraBackgroundImagePosition === 'right bottom' &&
      `
      &:after {
        transform: rotateY(180deg);
      }
      &:before {
        transform: rotateX(180deg);
      }
  `
    }

  `}
`;

const TextWrapper = styled(Grid)<HeroWrapperProps>`
  display: flex;
  flex-direction: column;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  & .description {
    display: flex;
    flex-direction: column;
    &-inner {
      flex-grow: 1;
    }
    .description-inner + .description-inner {
      padding-top: 2rem;
    }

    a {
      color: ${({ fontColor, theme }) => fontColor || theme.palette.grey[100]};
    }
  }
  ${({ theme }) => `${theme.breakpoints.down('md')} {
    .description h5{
      font-size: 1rem;
    }
  }`}
`;

export const Hero: React.FC<HeroProps> = ({
  title,
  subtitle,
  description,
  actions = [],
  actionGroupSize = DEFAULT_ACTION_GROUP_SIZE,
  hasExtraBackgroundImage = false,
  isDoubleExtraBackgroundImage = false,
  titleFontSize,
  subTitleFontSize,
  customTitleClass,
  textPosition,
  alignText,
  descriptionFontSize,
  getHeroHeight,
  ...rest
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery('(max-width:768px)');
  const pathName = useLocation().pathname;
  const innerActions = actions.reduce((acc: ButtonProps[][], item: ButtonProps, i) => {
    const id = Math.floor(i / actionGroupSize);
    const emptyArray: ButtonProps[] = [];
    acc[id] = emptyArray.concat(acc[id] || [], item);
    return acc;
  }, []);
  const heroRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleResize() {
      if (heroRef.current && !!getHeroHeight) {
        getHeroHeight(heroRef.current.clientHeight);
      }
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  });

  return (
    <HeroWrapper
      {...rest}
      theme={theme}
      ref={heroRef}
      hasExtraBackgroundImage={hasExtraBackgroundImage}
      isDoubleExtraBackgroundImage={isDoubleExtraBackgroundImage}
      className={pathName === '/' || pathName === '/hackathons' ? 'homePage' : undefined}
    >
      <Container maxWidth="xl" sx={{ position: 'relative', zIndex: '1' }}>
        <Grid
          container
          direction="column"
          flexDirection="column"
          className={textPosition && textPosition === 'center' ? 'textCentered' : undefined}
          alignItems={alignText && alignText === 'center' ? 'center' : undefined}
        >
          <TextWrapper
            item
            md={6}
            justifyContent={alignText && alignText === 'center' ? 'center' : undefined}
            alignItems={alignText && alignText === 'center' ? 'center' : undefined}
            theme={theme}
            fullWidth
          >
            <Typography
              component="h1"
              size={titleFontSize || 'h1'}
              gutterBottom
              className={customTitleClass}
            >
              {title}
            </Typography>
            {subtitle && (
              <Typography
                component="h2"
                size={subTitleFontSize || 'h4'}
                color="inherit"
                gutterBottom
              >
                {subtitle}
              </Typography>
            )}
            {description && (
              <div className="description">
                {typeof description === 'string' ? (
                  <Typography
                    size={`${descriptionFontSize || 'h5'}`}
                    component="h5"
                    color="inherit"
                    paragraph
                    className="description-inner"
                    fontWeight="100"
                    textAlign={alignText}
                    sx={{ marginBottom: 0 }}
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHtml(description, {
                        allowedTags: ['a', 'br'],
                        allowedAttributes: {
                          a: ['href', 'class', 'target', 'rel'],
                          code: ['class'],
                        },
                      }),
                    }}
                  />
                ) : (
                  description.map((paragraph, i) => (
                    <Typography
                      key={`${paragraph.substring(0, 2)}_${i}`}
                      size={`${descriptionFontSize || 'h5'}`}
                      component="h5"
                      color="inherit"
                      paragraph
                      className="description-inner"
                      fontWeight="100"
                      sx={{ marginBottom: 0 }}
                      dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(paragraph, {
                          allowedTags: ['a', 'br'],
                          allowedAttributes: {
                            a: ['href', 'class', 'target', 'rel'],
                            code: ['class'],
                          },
                        }),
                      }}
                    />
                  ))
                )}
              </div>
            )}
          </TextWrapper>
          {innerActions.length > 0 && (
            <Grid item sx={{ marginTop: 6 }}>
              {innerActions.map((list) => {
                const key = list.map((item) => item.label).join('-');
                return (
                  <Grid
                    container
                    key={key}
                    spacing={2}
                    mb="1rem"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    flexDirection={isMobile ? 'column' : 'row'}
                  >
                    {list.map((item, index) => (
                      <Grid
                        item
                        key={`${item.label}-${index}`}
                        style={{ width: isMobile ? '100%' : 'initial' }}
                      >
                        <Button
                          {...item}
                          variant="outlined"
                          size="medium"
                          color="secondary"
                          fullWidth={isMobile}
                        />
                      </Grid>
                    ))}
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Grid>
      </Container>
    </HeroWrapper>
  );
};
