import React, { useState } from 'react';

function TezosAgora({ color }: React.SVGProps<SVGSVGElement>) {
  const [isHover, setIsHover] = useState(false);
  const onMouseEnter = () => setIsHover(true);
  const onMouseLeave = () => setIsHover(false);

  return (
    <svg
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <circle cx="16" cy="16" r="15.5" stroke={isHover ? 'lightgray' : color || '#fff'} />
      <g clipPath="url(#clip0)">
        <path
          fill={isHover ? 'lightgray' : color || '#fff'}
          d="M21.992 19.805c-.174-2.939-4.045-4.274-4.415-4.412-.016-.007-.016-.021-.008-.036l3.992-3.826v-.412c0-.065-.06-.123-.128-.123h-7.357V8l-2.715.542v.39h.151s.666 0 .666.635v1.422h-2.102c-.038 0-.076.036-.076.072v.903h2.185v4.902c0 1.538 1.036 2.607 2.85 2.462a2.325 2.325 0 001.044-.36.45.45 0 00.22-.383V18.1c-.59.376-1.09.354-1.09.354-1.149 0-1.126-1.394-1.126-1.394v-5.097h5.293l-3.81 3.668-.016.866c0 .015.008.022.023.022 3.493.563 4.438 2.722 4.438 3.35 0 0 .378 3.054-2.82 3.264 0 0-2.095.086-2.465-.715-.015-.03 0-.058.03-.072.348-.152.582-.448.582-.845 0-.592-.378-1.076-1.172-1.076-.642 0-1.172.484-1.172 1.076 0 0-.302 2.57 4.19 2.498 5.126-.087 4.808-4.195 4.808-4.195z"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path
            fill={isHover ? 'lightgray' : color || '#fff'}
            d="M0 0H12V16H0z"
            transform="translate(10 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

const MemoTezosAgora = React.memo(TezosAgora);
export default MemoTezosAgora;
