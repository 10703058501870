import React from 'react';
import { Helmet } from 'react-helmet-async';

const defaultMeta = {
  title: 'Tezos Hackathons',
  description:
    'Build the next big thing on Tezos: Whether you’re a tech builder or an advocate, take part in a hackathon and help shape the future of Tezos.',
  url: 'http://tezos.com/hackathons/',
  page: 'main',
  shareImage: 'https://tezos.com/hackathons/assets/social/Tezos_Meta.jpg',
};

export const Urls = {
  main: 'http://tezos.com/hackathons',
  funding: 'http://tezos.com/hackathons/apply-for-funding',
  fundingSuccess: 'http://tezos.com/hackathons/apply-for-funding-success',
  organizer: 'http://tezos.com/hackathons/organize',
  participant: 'http://tezos.com/hackathons/participate',
  collaborate: 'http://tezos.com/hackathons/collaborate',
  collaborateSuccess: 'http://tezos.com/hackathons/collaborate-inquiry-success',
  upcoming: 'http://tezos.com/hackathons/all-hackathons',
  listHackathon: 'http://tezos.com/hackathons/list-hackathon',
};

export interface MetaTagsProps {
  title?: string;
  description?: string;
  page: keyof typeof Urls;
  url?: string;
}
export const getPageMetadata = (metaData: MetaTagsProps): MetaTagsProps => {
  const { title, description, page } = metaData;
  const defaultData = defaultMeta;
  return {
    title: title || defaultData?.title,
    description: description || defaultData?.description,
    url: Urls[page] || defaultData?.url,
    page: page || defaultData?.page,
  };
};

export const MetaTags: React.FC<MetaTagsProps> = (props) => {
  const meta = getPageMetadata(props);
  return (
    <Helmet>
      <title>{meta.title}</title>
      <meta name="description" content={meta.description} />
      <link rel="canonical" href={meta.url} />
      <meta property="og:url" content={meta.url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={meta.title} />
      <meta property="og:description" content={meta.description} />
      <meta property="og:image" content={defaultMeta.shareImage} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="hackathons.tzconnect.com" />
      <meta property="twitter:url" content={meta.url} />
      <meta name="twitter:title" content={meta.title} />
      <meta name="twitter:description" content={meta.description} />
      <meta name="twitter:image" content={defaultMeta.shareImage} />
      <link rel="icon" href="/favicon.ico" />
    </Helmet>
  );
};
