import React, { useState } from 'react';

function Telegram({ color }: React.SVGProps<SVGSVGElement>) {
  const [isHover, setIsHover] = useState(false);
  const onMouseEnter = () => setIsHover(true);
  const onMouseLeave = () => setIsHover(false);

  return (
    <svg
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        fill={isHover ? 'lightgray' : color || '#fff'}
        d="M21.92 9.085L7.747 14.38c-.967.377-.962.9-.177 1.132l3.638 1.1 8.42-5.146c.398-.235.761-.108.462.149l-6.82 5.964h-.002.001l-.25 3.634c.367 0 .53-.163.735-.356l1.768-1.665 3.676 2.63c.678.362 1.165.176 1.334-.607l2.413-11.019c.247-.96-.378-1.394-1.025-1.11z"
      />
      <circle cx="16" cy="16" r="15.5" stroke={isHover ? 'lightgray' : color || '#fff'} />
    </svg>
  );
}

const MemoTelegram = React.memo(Telegram);
export default MemoTelegram;
