import * as React from 'react';
import styled from '@emotion/styled';
import {
  Button,
  Link,
  Theme,
  useTheme,
  useMediaQuery,
  Popper,
  ClickAwayListener,
  Paper,
  MenuList,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// eslint-disable-next-line import/no-cycle
import { HeaderMenuItem } from '../Header/Header';

const CustomMenu = styled(Paper)<{ theme: Theme }>`
  min-width: ${({ theme }) => theme.spacing(25)};
  border-radius: 12px;
  a {
    display: block;
    padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(2)};
  }
  &:not(.full-screen) {
    ul {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    a {
      color: ${({ theme }) => theme.palette.text.primary};

      &:hover {
        background-color: ${({ theme }) => theme.palette.secondary.light};
        color: ${({ theme }) => theme.palette.primary.main};
      }
      &.Mui-selected {
        background-color: transparent;
      }
    }
    &::-webkit-scrollbar {
      width: 16px;
    }
    &::-webkit-scrollbar-track {
      background-color: white;
      border-top-right-radius: ${({ theme }) => theme.spacing(1)};
      border-bottom-right-radius: ${({ theme }) => theme.spacing(1)};
    }
    &::-webkit-scrollbar-thumb {
      backgroundcolor: ${({ theme }) => theme.palette.primary.main};
      border-radius: ${({ theme }) => theme.spacing(1)};
      border-width: 6px;
      border-style: solid;
      border-color: transparent;
      padding-right: ${({ theme }) => theme.spacing(1 / 2)};
      background-clip: padding-box;
    }
  }
  &.full-screen {
    width: 100vw;
    position: fixed;
    height: calc(100vh - 112px);
    background-color: ${({ theme }) => theme.palette.primary.main};
    box-shadow: none;
    overflow-y: auto;

    ul {
      height: 100%;
      overflow-y: auto;
      padding-left: ${({ theme }) => theme.spacing(2)};
    }

    a,
    a:hover,
    a.Mui-selected {
      color: ${({ theme }) => theme.palette.grey[100]};
      font-size: 2.25rem;
    }
  }
`;

const CustomPopper = styled(Popper)`
  z-index: 20000;
`;

export interface ListSubMenuProps {
  label: string;
  menuItems: HeaderMenuItem[];
}

export const ListSubMenu: React.FC<ListSubMenuProps> = ({ label, menuItems }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      isMobile
        ? setAnchorEl(event.currentTarget.parentNode?.parentElement || event.currentTarget)
        : setAnchorEl(event.currentTarget);
    },
    [isMobile],
  );
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="sub-menu-button"
        aria-controls={open ? 'sub-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="text"
        color="secondary"
        disableElevation
        disableFocusRipple
        fullWidth
        sx={{
          textTransform: 'none',
          justifyContent: 'space-between',
          fontSize: isMobile ? '2.5rem' : undefined,
          padding: 0,
        }}
        onClick={handleClick}
        endIcon={isMobile ? <ArrowForwardIosIcon /> : <KeyboardArrowDownIcon />}
      >
        {label}
      </Button>
      <CustomPopper
        id="sub-menu"
        role="presentation"
        placement={isMobile ? 'left-start' : 'bottom-start'}
        anchorEl={anchorEl}
        open={open}
      >
        <CustomMenu theme={theme} className={isMobile ? 'full-screen' : undefined}>
          <ClickAwayListener onClickAway={handleClose}>
            <div>
              {isMobile && (
                <Button
                  variant="text"
                  color="secondary"
                  fullWidth
                  disableElevation
                  disableFocusRipple
                  startIcon={<ArrowBackIcon />}
                  onClick={handleClose}
                  sx={{
                    fontSize: '1rem',
                    paddingX: theme.spacing(2),
                    justifyContent: 'flex-start',
                    backgroundColor: theme.palette.primary.main,
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                  }}
                >
                  Back
                </Button>
              )}
              <MenuList>
                {menuItems.map((item, i) => (
                  <Link
                    href={item.url}
                    underline="none"
                    variant="h6"
                    key={`${label}_${item.label}_${i}`}
                  >
                    {item.label}
                  </Link>
                ))}
              </MenuList>
            </div>
          </ClickAwayListener>
        </CustomMenu>
      </CustomPopper>
    </>
  );
};
