import React, { useCallback, useEffect, useState } from 'react';
import { AnimationProps, motion } from 'framer-motion';
import styled from '@emotion/styled';
import { Header } from '../../design-system/molecules/Header';
import { Footer, FooterProps } from '../../design-system/molecules/Footer';
import { HeaderMenuItem } from '../../design-system/molecules/Header/Header';
import { MetaTags } from '../../design-system/molecules/MetaTags';
import { Urls } from '../../design-system/molecules/MetaTags/MetaTags';
import MemoTezosIcon from '../../icons/Tezos';
import MemoTwitter from '../../icons/Sharing/Twitter';
import MemoTelegram from '../../icons/Sharing/Telegram';
import MemoRedit from '../../icons/Sharing/Redit';
import MemoDiscord from '../../icons/Sharing/Discord';
import MemoStackExchange from '../../icons/Sharing/StackExchange';
import MemoRiot from '../../icons/Sharing/Riot';
import MemoGitlabs from '../../icons/Sharing/Gitlabs';
import MemoTezosAgora from '../../icons/Sharing/TezosAgora';
import MemoGithub from '../../icons/Sharing/Github';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

interface MainPageProps {
  title?: string;
  description?: string;
  heroSize?: number;
  page?: keyof typeof Urls;
}

const pageVariants: AnimationProps['variants'] = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
    transition: { duration: 0.35 },
  },
  out: {
    opacity: 0,
    transition: { duration: 0.2 },
  },
};

const HeaderMenuItems: HeaderMenuItem[] = [
  {
    label: 'Learn',
    subMenu: [
      {
        label: 'Discover Tezos',
        url: 'https://tezos.com/build-play-collect',
      },
      {
        label: 'Stats & Facts',
        url: 'https://tezos.com/stats/',
      },
      {
        label: 'Energy Footprint',
        url: 'https://tezos.com/carbon/',
      },
      {
        label: 'What is an NFT?',
        url: 'https://tezos.com/non-fungible-token/',
      },
    ],
  },
  {
    label: 'Build',
    subMenu: [
      {
        label: 'Hackathons',
        url: '/hackathons',
      },
      {
        label: 'Developer Portal',
        url: 'https://tezos.com/developer-portal',
      },
    ],
  },
  {
    label: 'Ecosystem',
    subMenu: [
      {
        label: 'Ecosystem Projects',
        url: 'https://tezos.com/ecosystem',
      },
      {
        label: 'Community & Events',
        url: 'https://tezos.com/community',
      },
      {
        label: 'Careers',
        url: 'https://tezos.com/careers',
      },
    ],
  },
  {
    label: 'News',
    url: 'https://tezos.com/#news',
  },
  {
    label: 'Gear',
    url: 'https://gear.tezos.com',
  },
];

const FooterLinkGroups = [
  {
    title: 'GENERAL',
    links: [
      {
        label: 'Discover Tezos',
        href: 'https://tezos.com/build-play-collect',
      },
      {
        label: 'Ecosystem Projects',
        href: 'https://tezos.com/ecosystem',
      },
      {
        label: 'Community & Events',
        href: 'https://tezos.com/community',
      },
      {
        label: 'Stats & Facts',
        href: 'https://tezos.com/stats',
      },
      {
        label: 'Energy Footprint',
        href: 'https://tezos.com/carbon',
      },
      {
        label: 'Tezos Visual Identity',
        href: 'https://tezos.com/visual-identity',
      },
    ],
  },
  {
    title: 'TECHNOLOGY',
    links: [
      {
        label: 'White Paper',
        href: 'https://tezos.com/whitepaper.pdf',
      },
      {
        label: 'Getting Started',
        href: 'https://tezos.com/learn/getting-started',
      },
      {
        label: 'Developer Portal',
        href: 'https://tezos.com/developer-portal',
      },
      {
        label: 'Tezos Wiki',
        href: 'https://wiki.tezos.com',
      },
      {
        label: 'GitLab',
        href: 'https://gitlab.com/tezos',
      },
    ],
  },
];

const SharingList = [
  {
    icon: <MemoTwitter />,
    url: 'https://twitter.com/tezos',
    showInMobile: true,
  },
  {
    icon: <MemoTelegram />,
    url: 'https://t.me/tezosplatform',
  },
  {
    icon: <MemoRedit />,
    url: 'https://www.reddit.com/r/tezos/',
    showInMobile: true,
  },
  {
    icon: <MemoTezosAgora />,
    url: 'https://forum.tezosagora.org/',
  },
  {
    icon: <MemoStackExchange />,
    url: 'https://tezos.stackexchange.com/',
    showInMobile: true,
  },
  {
    icon: <MemoRiot />,
    url: 'https://riot.tzchat.org/#/welcome',
    showInMobile: true,
  },
  {
    icon: <MemoGitlabs />,
    url: 'https://gitlab.com/tezos/tezos',
    showInMobile: true,
  },
  {
    icon: <MemoDiscord />,
    url: 'https://discord.com/invite/udZwhQn',
  },
  {
    icon: <MemoGithub />,
    url: 'https://github.com/tezos/tezos',
  },
];

const FooterData: FooterProps = {
  logo: <MemoTezosIcon width={200} />,
  getInTouch: `Feedback or comments? Get in touch with us at <a class="white" href="mailto:reachout@tezos.com">reachout@tezos.com</a>`,
  linkGroups: FooterLinkGroups,
  privacyPolicy: {
    title: 'Privacy Notice',
    url: 'https://tezos.com/privacy-notice',
    target: '_blank',
  },
  sharingList: SharingList,
};

const defaultPage = 'main';

export const MainPage: React.FC<MainPageProps> = ({
  title,
  children,
  description,
  heroSize = 0,
  page = defaultPage,
}) => {
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
  const [headerColor, setHeaderColor] = useState(false);
  const [scrollHeight, setScrollHeight] = useState(0);

  const toggleMobileMenu = useCallback(() => {
    setMobileMenuIsOpen(!mobileMenuIsOpen);
  }, [mobileMenuIsOpen]);

  useEffect(() => {
    const checkHeaderStatus = () => {
      mobileMenuIsOpen || scrollHeight > heroSize ? setHeaderColor(true) : setHeaderColor(false);
    };
    const onScroll = (e: any) => {
      if (!mobileMenuIsOpen) {
        setScrollHeight(e.target.documentElement.scrollTop);
        checkHeaderStatus();
      }
    };
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [mobileMenuIsOpen, scrollHeight, heroSize, headerColor]);

  return (
    <PageContainer>
      <MetaTags title={title} description={description} page={page} />
      <Header menuItems={HeaderMenuItems} mobileMenuClickHandler={toggleMobileMenu} />
      <main>
        <motion.div initial="initial" animate="in" exit="out" variants={pageVariants}>
          {children}
        </motion.div>
      </main>
      <Footer {...FooterData} />
    </PageContainer>
  );
};
