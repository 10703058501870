import * as React from 'react';

function TezosIcon({ fill = '#FFFFFF', width, height, ...rest }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={width}
      height={height}
      viewBox="0 0 1523.6 221.6"
      {...rest}
    >
      <path
        fill={fill}
        d="M158.6,163.5c-2.3-40.7-53.5-59.2-58.4-61.1c-0.2-0.1-0.2-0.3-0.1-0.5l52.8-53v-5.7c0-0.9-0.8-1.7-1.7-1.7H53.9
	V9.6l0,0V0L18,7.5v5.4h2c0,0,8.8,0,8.8,8.8v19.7H1c-0.5,0-1,0.5-1,1v12.5h28.9c0,0,0,13.3,0,28.8v39.1c0,21.3,13.7,36.1,37.7,34.1
	c5.1-0.4,9.8-2.4,13.8-5c1.8-1.2,2.9-3.1,2.9-5.3v-6.7c-7.8,5.2-14.4,4.9-14.4,4.9c-15.2,0-14.9-19.3-14.9-19.3V54.9h70l-50.4,50.8
	c-0.1,6.7-0.2,11.9-0.2,12c0,0.2,0.1,0.3,0.3,0.3c46.2,7.8,58.7,37.7,58.7,46.4c0,0,5,42.3-37.3,45.2c0,0-27.7,1.2-32.6-9.9
	c-0.2-0.4,0-0.8,0.4-1c4.6-2.1,7.7-6.2,7.7-11.7c0-8.2-5-14.9-15.5-14.9c-8.5,0-15.5,6.7-15.5,14.9c0,0-4,35.6,55.4,34.6
	C162.8,220.4,158.6,163.5,158.6,163.5z"
      />
      <g>
        <path fill={fill} d="M316.8,50.1h-39.2v106.3h-9.7V50.1h-39.1V42h88V50.1z" />
        <path
          fill={fill}
          d="M358.2,158c-7.2,0-13.8-1.8-19.6-5.3c-5.8-3.6-10.4-8.5-13.6-14.9c-3.2-6.4-4.9-13.5-4.9-21.4V113
		c0-8.2,1.6-15.5,4.8-22.1c3.2-6.6,7.6-11.7,13.2-15.4s11.8-5.6,18.4-5.6c10.3,0,18.5,3.5,24.6,10.6c6.1,7,9.1,16.7,9.1,28.9v5.3
		h-60.7v1.8c0,9.6,2.8,17.7,8.3,24.1c5.5,6.4,12.5,9.6,20.9,9.6c5,0,9.5-0.9,13.3-2.8c3.9-1.8,7.3-4.8,10.5-8.8l5.9,4.5
		C381.4,153,371.3,158,358.2,158z M356.5,77.8c-7.1,0-13,2.6-17.9,7.8c-4.8,5.2-7.8,12.2-8.8,20.9h51v-1c-0.3-8.2-2.6-14.8-7-20
		C369.4,80.4,363.6,77.8,356.5,77.8z"
        />
        <path
          fill={fill}
          d="M414.6,148.5h55.3v7.9H403v-6.9l51.6-70h-50v-8.2h61.9v7.1L414.6,148.5z"
        />
        <path
          fill={fill}
          d="M480.3,112.6c0-8.2,1.6-15.5,4.8-22.1c3.2-6.6,7.7-11.6,13.4-15.3c5.8-3.6,12.4-5.5,19.7-5.5
		c11.3,0,20.5,4,27.5,11.9c7,7.9,10.5,18.5,10.5,31.6v2c0,8.2-1.6,15.6-4.8,22.2c-3.2,6.6-7.6,11.6-13.4,15.2
		c-5.8,3.6-12.3,5.3-19.7,5.3c-11.3,0-20.4-4-27.5-11.9c-7-7.9-10.6-18.5-10.6-31.6V112.6z M489.7,115.2c0,10.2,2.6,18.5,7.9,25
		c5.3,6.5,12.2,9.8,20.7,9.8c8.5,0,15.4-3.3,20.6-9.8c5.3-6.5,7.9-15.1,7.9-25.8v-1.8c0-6.5-1.2-12.4-3.6-17.8
		c-2.4-5.4-5.8-9.6-10.1-12.5c-4.4-3-9.3-4.4-14.9-4.4c-8.4,0-15.2,3.3-20.5,9.9c-5.3,6.6-7.9,15.2-7.9,25.8V115.2z"
        />
        <path
          fill={fill}
          d="M626.6,134.8c0-4.7-1.9-8.4-5.6-11.2s-9.4-5-16.9-6.6s-13.4-3.4-17.6-5.5c-4.2-2.1-7.3-4.6-9.3-7.6
		c-2-3-3-6.6-3-10.8c0-6.7,2.8-12.2,8.4-16.6c5.6-4.4,12.8-6.6,21.5-6.6c9.5,0,17.1,2.3,22.8,7c5.7,4.7,8.6,10.8,8.6,18.4h-9.4
		c0-5-2.1-9.1-6.2-12.4c-4.2-3.3-9.4-5-15.8-5c-6.2,0-11.2,1.4-14.9,4.1c-3.7,2.8-5.6,6.3-5.6,10.7c0,4.2,1.6,7.5,4.7,9.9
		c3.1,2.3,8.8,4.5,17.1,6.4c8.3,1.9,14.4,4,18.6,6.2c4.1,2.2,7.2,4.8,9.2,7.9c2,3.1,3,6.8,3,11.2c0,7.2-2.9,12.9-8.7,17.2
		c-5.8,4.3-13.4,6.4-22.7,6.4c-9.9,0-17.9-2.4-24.1-7.2c-6.2-4.8-9.2-10.9-9.2-18.2h9.4c0.4,5.5,2.7,9.8,7,12.9
		c4.3,3.1,9.9,4.6,16.9,4.6c6.6,0,11.8-1.4,15.9-4.3C624.6,142.9,626.6,139.2,626.6,134.8z"
        />
      </g>
    </svg>
  );
}

const MemoTezosIcon = React.memo(TezosIcon);
export default MemoTezosIcon;
